import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// PAGES
import InvalidRoute from '../Validate/InvalidRoute';
import Steps from './steps';
import ExploreMore from '../ExploreMore';
import CompleteSteps from '../CompleteSteps';
// import PaidTrialClass from '../PaidTrialClass';

const Onboarding: React.FC = () => (
  <Routes>
    <Route path="/" element={<Navigate to="steps" />} />
    <Route path="*" element={<InvalidRoute />} />
    <Route path="steps" element={<Steps pageType="onboarding" />} />
    <Route path="complete/trial-class-details" element={<CompleteSteps />} />
    {/* <Route path="complete/confirmpayment" element={<PaidTrialClass />} /> */}
    <Route path="exploreMore" element={<ExploreMore />} />
  </Routes>
);

export default Onboarding;
