export const ApiPaths = {
  curriculums: '/api/v1/curriculum',
  subject: '/api/v1/subject',
  trialUser: '/api/v1/user',
  updateTrialUser: '/api/v1/user/trial/update',
  findUs: '/api/v1/findUs',
  signup: '/api/v1/signup',
  grade: '/api/v1/grade',
  settings: '/api/v1/settings',
  freeSlotSelect: '/api/v1/getFreeSlots',
  trial: '/api/v1/trial',
  freeTutor: '/api/v1/free_tutors',
};

export default ApiPaths;
