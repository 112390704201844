import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
// STORE
import { RootState } from '..';
import { IUser } from '../SignupSlice';
// API
import {
  createTrialClass,
  getTrialClass,
  updateTrialTopicAndSpecialRequirements,
} from './TrialSliceApi';

export interface ITrialClass {
  id: string;
  subject: string;
  curriculum: string;
  grade: string;
  status: 'pending' | 'scheduled';
  selectedTime?: number;
  tutor?: IUser;
  freeSlots: number[];
  studentId: string;
  topic?: string;
  specialRequirements?: string;
}
export interface ITrialClassState {
  status: 'idle' | 'loading' | 'rejected';
  trialClass: ITrialClass | undefined;
  isTrialClassCreatingOrLoading: boolean;
}

const initialState: ITrialClassState = {
  status: 'idle',
  trialClass: undefined,
  isTrialClassCreatingOrLoading: false,
};

export const TrialClassSlice = createSlice({
  name: 'trial',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder
      .addCase(createTrialClass.pending, (state) => {
        state.status = 'loading';
        state.isTrialClassCreatingOrLoading = true;
      })
      .addCase(createTrialClass.fulfilled, (state, action) => {
        state.status = 'idle';
        state.trialClass = action.payload;
        // eslint-disable-next-line
        const trialClass: any = action.payload;
        if (trialClass && trialClass.bookedBy) {
          Cookies.set('tutopiya_trial_booked_by_id', trialClass.bookedBy.id);
          Cookies.set(
            'tutopiya_trial_booked_by_role',
            trialClass.bookedBy.role
          );
          Cookies.set(
            'tutopiya_trial_booked_by_email',
            trialClass.bookedBy.email
          );
          Cookies.set(
            'tutopiya_trial_booked_by_firstName',
            trialClass.bookedBy.firstName
          );
          Cookies.set(
            'tutopiya_trial_booked_by_lastName',
            trialClass.bookedBy.lastName
          );
        }
        window.location.replace(
          `${process.env.REACT_APP_BASE_URL}/onboarding/complete/trial-class-details?id=${action.payload.id}`
        );
      })
      .addCase(createTrialClass.rejected, (state) => {
        state.status = 'rejected';
        state.isTrialClassCreatingOrLoading = false;
      })
      // GET TRIAL CLASS
      .addCase(getTrialClass.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTrialClass.fulfilled, (state, action) => {
        state.status = 'loading';
        state.status = 'idle';
        state.trialClass = action.payload;
        state.isTrialClassCreatingOrLoading = false;
      })
      .addCase(getTrialClass.rejected, (state) => {
        state.status = 'rejected';
        state.isTrialClassCreatingOrLoading = false;
      })
      // UPDATE TRIAL TOPIC & SPECIAL REQUIREMENTS
      .addCase(updateTrialTopicAndSpecialRequirements.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        updateTrialTopicAndSpecialRequirements.fulfilled,
        (state, action) => {
          state.status = 'idle';
          state.trialClass = action.payload;
        }
      )
      .addCase(updateTrialTopicAndSpecialRequirements.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getTrialState = (state: RootState): RootState['trial'] =>
  state.trial;

export * from './TrialSliceApi';
export default TrialClassSlice.reducer;
